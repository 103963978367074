const rules = {
	user: {
		static: [],
	},

	supervisor: {
		static: [
			"dashboard:view",
			"tickets:view",
			"quickMessages:view",
			"kanban:view",
			"contacts:view",
			"schedules:view",
			"tags:view",
			"internalchat:view",
			"helps:view",
			"campaigns:view",
			"drawer-admin-items:view",
			"queues:view",
			"connections:view",
			"campaigns:view",
			"tickets-manager:showall",
		],
	},
// Gerentes "Por loja" (terão acesso total as funções das empresas) porém sem o painel de conf. maiores)
	gerente: {
		static: [
			"dashboard:view",
			"tickets:view",
			"quickMessages:view",
			"kanban:view",
			"contacts:view",
			"schedules:view",
			"tags:view",
			"internalchat:view",
			"helps:view",
			"menuadmin:view",
			"campaigns:view",
			"drawer-admin-items:view",
			"users:view",
			"queues:view",
			"connections:view",
			"settings:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"contacts-page:deleteContact",
			// "contacts-page:deleteAllContacts",
			"connections-page:actionButtons",
			"connections-page:addConnection",
			"connections-page:editOrDeleteConnection",
			"tickets-manager:closeAll",
			"aniversarios_viewer",
		],
	},
// Diretores e afins
	admin: {
		static: [
			"dashboard:view",
			"tickets:view",
			"quickMessages:view",
			"kanban:view",
			"contacts:view",
			"schedules:view",
			"tags:view",
			"internalchat:view",
			"helps:view",
			"menuadmin:view",
			"campaigns:view",
			"flowbuilder:view",
			"drawer-admin-items:view",
			// "messagesAPI:view",
			"users:view",
			"queues:view",
			// "prompts:view",
			// "queueIntegration:view",
			"connections:view",
			"allConnections:view",
			"files:view",
			"financeiro:view",
			"settings:view",
			"companies:view",
			"allconfig:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"contacts-page:deleteContact",
			// "contacts-page:deleteAllContacts",
			"connections-page:actionButtons",
			"connections-page:addConnection",
			"connections-page:editOrDeleteConnection",
			"tickets-manager:closeAll",
			"queue-pref:view",
			"aniversarios_viewer",
		],
		
	},
// serão os responsáveis pelo cadastro, planos, valores, e afins (todo o sistema liberado)
	masteradmin: {
		static: [
			"dashboard:view",
			"tickets:view",
			"quickMessages:view",
			"kanban:view",
			"contacts:view",
			"schedules:view",
			"tags:view",
			"internalchat:view",
			"helps:view",
			"menuadmin:view",
			"campaigns:view",
			"flowbuilder:view",
			"drawer-admin-items:view",
			"messagesAPI:view",
			"users:view",
			"queues:view",
			"prompts:view",
			"queueIntegration:view",
			"connections:view",
			"allConnections:view",
			"files:view",
			"financeiro:view",
			"settings:view",
			"master:view",
			"companies:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"contacts-page:deleteContact",
			// "contacts-page:deleteAllContacts",
			"connections-page:actionButtons",
			"connections-page:addConnection",
			"connections-page:editOrDeleteConnection",
			"tickets-manager:closeAll",
			"aniversarios_viewer",
		],
		
	},
};

export default rules;
