import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import api from "../../services/api";
import useQueues from "../../hooks/useQueues";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    gap: theme.spacing(4),
  },
  formContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  saveButton: {
    margin: "16px", // Adiciona espaçamento de 4px em todos os lados
  },
  previewContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column", // Garante que a imagem fique acima do texto
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "10px",
    width: "300px",
    height: "auto", // Permite que o container ajuste o tamanho conforme o conteúdo
    backgroundColor: "#f0f0f0",
    padding: "10px",
  },  
  messageBox: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#dcf8c6",
    borderRadius: "10px",
    textAlign: "center",
    fontSize: "14px",
    wordWrap: "break-word",
    marginTop: "10px", // Adiciona um espaçamento entre a imagem e o texto
  },
  imagePreview: {
    width: "100%",
    borderRadius: "10px",
  },
  formRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: theme.spacing(2),
  },
  formControl: {
    flex: 1,
    minWidth: 150,
  },
  input: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  fileInput: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    maxHeight: 400, // Limita a altura da tabela a cerca de 15 linhas
    overflowY: "auto", // Adiciona barra de rolagem se necessário
  },
  tableCellCentered: {
    textAlign: "center",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  pageInfo: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  fixedInstructionBox: {
    position: "fixed", // Fixa no final da tela
    bottom: 0, // Alinha na parte inferior
    left: 0,
    width: "100%", // Ocupa toda a largura da tela
    backgroundColor: "#fff3cd", // Cor de fundo amarela clara (aviso)
    borderTop: "2px solid #ffeeba", // Linha no topo para separação
    padding: "15px",
    textAlign: "center",
    fontSize: "14px",
    color: "#856404", // Cor do texto marrom escuro para boa leitura
    fontWeight: "bold",
    zIndex: 1000, // Garante que fique sobre outros elementos
  },  
}));

const ConfiguracoesAniversarios = () => {
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();

  const [mensagemPadrao, setMensagemPadrao] = useState("");
  const [usuarioEnvio, setUsuarioEnvio] = useState("");
  const [filaEnvio, setFilaEnvio] = useState("");
  const [statusMensagem, setStatusMensagem] = useState("Fechado");
  const [queues, setQueues] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [aniversariantes, setAniversariantes] = useState([]);
  const [token, setToken] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedConnection, setSelectedConnection] = useState("");
  const [connections, setConnections] = useState([]);
    const [ativo, setAtivo] = useState(false);
  const [sendSignature, setSendSignature] = useState(false);

  const { user } = useContext(AuthContext);
  const { companyId } = user || {};

  const [paginaAtual, setPaginaAtual] = useState(1);
  const ITENS_POR_PAGINA = 50;

  const validateForm = () => {
    const newErrors = {};
    if (!mensagemPadrao.trim()) newErrors.mensagemPadrao = "Campo obrigatório";
    if (!usuarioEnvio) newErrors.usuarioEnvio = "Campo obrigatório";
    if (!filaEnvio) newErrors.filaEnvio = "Campo obrigatório";
    if (!statusMensagem) newErrors.statusMensagem = "Campo obrigatório";
    if (!selectedConnection) newErrors.selectedConnection = "Campo obrigatório";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Buscar a configuração ativa ao carregar a página
  useEffect(() => {
    const fetchActiveConfig = async () => {
      try {
        const { data } = await api.get("/api/configaniversario/ativo");
        if (data) {
          setAtivo(data.ativo);
          setMensagemPadrao(data.mensagemPadrao);
          setUsuarioEnvio(data.usuarioEnvio);
          setFilaEnvio(data.filaEnvio);
          setStatusMensagem(data.statusEnvio);
          setSelectedConnection(data.conexaoEnvio);
          setSendSignature(data.assinaturaEnvio === "Sim");
          setFileName(data.arquivoEnvio);
          setFileUrl(data.arquivoEnvioUrl || ""); // Agora a URL da imagem virá do banco!
        }
      } catch (error) {
        console.error("Erro ao buscar configuração ativa:", error);
      }
    };
  
    fetchActiveConfig();
  }, []);

  const handleToggleAtivo = async () => {
    if (!ativo) {
      if (!mensagemPadrao.trim() || !usuarioEnvio || !filaEnvio || !selectedConnection) {
        alert("Erro: Para ativar, é necessário configurar todos os campos corretamente.");
        return;
      }
    }

    try {
      await api.post("/api/configaniversario/ativo", { ativo: !ativo });
      setAtivo(!ativo);
      alert(`Mensagens de aniversário ${!ativo ? "ativadas" : "desativadas"} com sucesso!`);
    } catch (error) {
      console.error("Erro ao atualizar status ativo:", error);
      alert("Erro ao atualizar status.");
    }
  };
  
  

  useEffect(() => {
    const loadQueues = async () => {
      const list = await findAllQueues();
      setQueues(list);
    };
    loadQueues();

    if (!companyId) return;

    const fetchConnections = async () => {
      try {
        const { data } = await api.get(`/whatsapp`, { params: { companyId, session: 0 } });
        setConnections(data.map((whatsapp) => ({ ...whatsapp, selected: false })));
      } catch (err) {
        console.error("Erro ao buscar conexões", err);
      }
    };
    fetchConnections();

    const fetchUsers = async () => {
      try {
        const { data } = await api.get("/users/");
        setUsuarios(data.users);
      } catch (err) {
        console.error("Erro ao buscar usuários", err);
      }
    };
    fetchUsers();

    const fetchAniversariantes = async () => {
      try {
        const apiUrl = `/contacts/birthdays`; // URL usada na requisição
        console.log(`🔍 Buscando aniversariantes na API: ${apiUrl}`);
  
        const { data } = await api.get(apiUrl);
        
        if (data && Array.isArray(data.contacts)) {
          console.log("✅ Aniversariantes recebidos:", data.contacts);
          setAniversariantes(data.contacts);
        } else {
          console.error("❌ Formato inesperado da API:", data);
          setAniversariantes([]);
        }
      } catch (err) {
        console.error("❌ Erro ao buscar aniversariantes:", err);
        setAniversariantes([]);
      }
    };
  
    fetchAniversariantes();

    const fetchToken = async () => {
      try {
        const { data } = await api.get("/whatsapp", { params: { companyId, session: 0 } });
        if (data.length > 0 && data[0].token) {
          setToken(data[0].token);
        } else {
          console.warn("Nenhum WhatsApp configurado com token disponível.");
          setToken("");
        }
      } catch (err) {
        console.error("Erro ao buscar token do WhatsApp", err);
        setToken("");
      }
    };
    fetchToken();
  }, [companyId]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileUrl(URL.createObjectURL(selectedFile)); // Atualiza o preview localmente
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("mensagemPadrao", mensagemPadrao);
      formData.append("usuarioEnvio", Number(usuarioEnvio));
      formData.append("filaEnvio", Number(filaEnvio));
      formData.append("statusEnvio", statusMensagem);
      formData.append("conexaoEnvio", Number(selectedConnection));
      formData.append("tokenEnvio", token);
      formData.append("assinaturaEnvio", sendSignature ? "true" : "false");
      formData.append("ativo", true);
      formData.append("companyId", companyId);
  
      if (file) {
        formData.append("arquivoEnvio", file);
      }
  
      await api.post("/api/configaniversario", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      alert("Configuração atualizada com sucesso!");
      window.location.reload(); // Atualiza a página para exibir a nova configuração ativa
    } catch (error) {
      console.error("Erro ao salvar a configuração", error);
      alert("Erro ao salvar configuração!");
    }
  };
  

  // Paginação
  const totalPaginas = Math.ceil(aniversariantes.length / ITENS_POR_PAGINA);
  const aniversariantesPaginados = aniversariantes.slice(
    (paginaAtual - 1) * ITENS_POR_PAGINA,
    paginaAtual * ITENS_POR_PAGINA
  );

  return (
    <Container className={classes.container}>
      <div className={classes.formContainer}>
        <Typography variant="h4" gutterBottom>
          Configurações Aniversários
        </Typography>

        <FormControlLabel
                  control={<Switch checked={ativo} onChange={handleToggleAtivo} color="primary" />}
                  label={ativo ? "Mensagens de aniversário ATIVADAS" : "Mensagens de aniversário DESATIVADAS"}
                />

        <TextField
          label="Mensagem Padrão"
          multiline
          rows={4}
          variant="outlined"
          className={classes.input}
          value={mensagemPadrao}
          onChange={(e) => setMensagemPadrao(e.target.value)}
          error={!!errors.mensagemPadrao}
          helperText={errors.mensagemPadrao}
        />

        <input
          type="file"
          onChange={handleFileChange}
          className={classes.fileInput}
        />
        {file && <Typography variant="body2">Arquivo selecionado: {file.name}</Typography>}

        {fileName && (
          <Typography variant="body2">
            Arquivo salvo: <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileName}</a>
          </Typography>
        )}



        <div className={classes.formRow}>
          <FormControl className={classes.formControl}>
            <InputLabel>Usuário de Envio</InputLabel>
            <Select
              value={usuarioEnvio}
              onChange={(e) => setUsuarioEnvio(e.target.value)}
            >
              {usuarios.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Fila de Envio</InputLabel>
            <Select
              value={filaEnvio}
              onChange={(e) => setFilaEnvio(e.target.value)}
            >
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Status da Mensagem</InputLabel>
            <Select
              value={statusMensagem}
              onChange={(e) => setStatusMensagem(e.target.value)}
            >
              <MenuItem value="closed">Fechado</MenuItem>
              <MenuItem value="pending">Pendente</MenuItem>
              <MenuItem value="open">Aberto</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Conexões</InputLabel>
            <Select
              value={selectedConnection}
              onChange={(e) => setSelectedConnection(e.target.value)}
            >
              {connections.map((connection) => (
                <MenuItem key={connection.id} value={connection.id}>
                  {connection.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel id="send-signature-label">
              Enviar assinatura
            </InputLabel>
            <Select
              value={sendSignature}
              onChange={(e) => setSendSignature(e.target.value)}
              labelId="send-signature-label"
            >
              <MenuItem value="true">Sim</MenuItem>
              <MenuItem value="false">Não</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Button variant="contained" color="primary" onClick={handleSave} className={classes.saveButton}>
          Salvar
        </Button>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Typography variant="h6" align="center" gutterBottom>
            Aniversariantes do dia 🎉 ({aniversariantes.length})
          </Typography>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellCentered}><b>Nome</b></TableCell>
                <TableCell className={classes.tableCellCentered}><b>Telefone</b></TableCell>
                <TableCell className={classes.tableCellCentered}><b>Data Aniversário</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aniversariantesPaginados.length > 0 ? (
                aniversariantesPaginados.map((aniversariante, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCellCentered}>{aniversariante.name}</TableCell>
                    <TableCell className={classes.tableCellCentered}>{aniversariante.number}</TableCell>
                    <TableCell className={classes.tableCellCentered}>{aniversariante.aniversario}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Nenhum aniversariante hoje!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalPaginas > 1 && (
          <div className={classes.pagination}>
            <IconButton disabled={paginaAtual === 1} onClick={() => setPaginaAtual(paginaAtual - 1)}>
              <ArrowBack />
            </IconButton>
            <Typography variant="body1" className={classes.pageInfo}>
              Página {paginaAtual} de {totalPaginas}
            </Typography>
            <IconButton disabled={paginaAtual === totalPaginas} onClick={() => setPaginaAtual(paginaAtual + 1)}>
              <ArrowForward />
            </IconButton>
          </div>
        )}
      </div>

      <div className={classes.previewContainer}>
        {fileUrl && <img src={fileUrl} alt="Preview" className={classes.imagePreview} />}
        {mensagemPadrao && <div className={classes.messageBox}>{mensagemPadrao}</div>}
      </div>

      <div className={classes.fixedInstructionBox}>
        As mensagens de aniversário estão configuradas para irem <b>DIARIAMENTE</b> a partir das <b>09:10</b> da manhã e pausar às <b>17:59</b> de <b>Seg a Sáb</b> e de <b>domingo</b> pausar às <b>16:59</b>.
      </div>

    </Container>
  );
};

export default ConfiguracoesAniversarios;
